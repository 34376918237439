<template>
  <spinner v-if="loader" />
  <div
    class="shadow-color-950/30 absolute left-0 top-18 z-40 block h-fit w-full bg-gray-100 px-6 py-3 shadow-lg sm:top-28 sm:border-t lg:top-28 lg:border-t lg:px-9 lg:py-8"
  >
    <div
      class="-translate-y-1/8 relative top-1/2 mx-auto flex w-full pr-10 md:max-w-xl md:pr-14 lg:max-w-2xl"
    >
      <input
        ref="searchInput"
        type="text"
        class="block w-full border-spacing-2 rounded-lg border-2 border-gray-900 bg-gray-50 px-4 py-2 text-gray-950 outline-none"
        :placeholder="t('placeholder')"
        @input="handleInput"
        @keydown.enter="submitForm"
        @focus="fillInput"
      />
      <search-icon
        @click="submitForm"
        class="absolute right-14 top-1/2 -translate-y-1/2 cursor-pointer fill-gray-400 md:right-18"
      />
      <close-icon
        class="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer md:right-3"
        @click="emit('close-search')"
      />
    </div>
    <div
      v-if="autoComplete && autoComplete.length > 0"
      class="relative top-full mx-auto mt-4 flex w-full pr-10 md:max-w-xl md:pr-14 lg:max-w-2xl"
    >
      <ul
        class="w-full list-none rounded-md border border-gray-300 bg-white p-0 shadow-sm"
      >
        <li
          v-for="item in autoComplete"
          :key="item"
          class="w-full cursor-pointer p-4 hover:bg-gray-100"
          @click="
            router.push(localePath(item.url));
            emit('close-search');
          "
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<i18n lang="json" src="@/translations/pages/search.json"></i18n>
<script lang="ts" setup>
import SearchIcon from "../../base/icon/icons/SearchIcon.vue";
import CloseIcon from "../../base/icon/icons/CloseIcon.vue";
import {
  navigateTo,
  useI18n,
  useLocalePath,
  useStrapiClient,
  watch,
} from "../../../.nuxt/imports";
import { useRoute, useRouter } from "vue-router";
import { transformSearchResult } from "../../../composables/common";
import { useSearchStore } from "../../../store/search";
import Spinner from "../../base/icon/icons/Spinner.vue";

const emit = defineEmits(["close-search"]);
const { locale, t } = useI18n();
const searchInput = ref(null);
const client = useStrapiClient();
const autoComplete = ref();
const localePath = useLocalePath();
const searchStore = useSearchStore();
const router = useRouter();
const route = useRoute();
const loader = ref(false);

const fillInput = async () => {
  searchInput.value.value = route.query.search ?? "";
};

const handleInput = async () => {
  if (searchInput.value.value.length > 3) {
    try {
      const res = await client(
        `/elasticsearch/custom-search?suggest=${searchInput.value.value}&locale=${locale.value}`,
        {
          method: "GET",
        } as any,
      );

      if (res) {
        autoComplete.value = transformSearchResult(
          (res as []).filter(
            (item: { collection: string; name: string }) => item.name,
          ),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
};

const submitForm = async () => {
  try {
    loader.value = true;
    const res = await client(
      `/elasticsearch/custom-search?search=${searchInput.value.value}&locale=${locale.value}`,
      {
        method: "GET",
      } as any,
    );

    if (res) {
      loader.value = false;
      emit("close-search");
      searchStore.setSearchResult(transformSearchResult(res));
      await navigateTo({
        path: localePath("search"),
        query: {
          search: searchInput.value.value,
        },
      });
    }
  } catch (e) {
    loader.value = false;
    console.log(e);
  }
};

watch(
  () => route.path,
  (value) => {
    if (value) {
      emit("close-search");
    }
  },
);

defineExpose({
  searchInput,
});
</script>
